import * as React from 'react'
import Template from "../components/template";

const Page = () => {

    return (
        <Template page="contact" title="Contact">
            <iframe
                className="-mx-4"
                src="https://docs.google.com/forms/d/e/1FAIpQLSerQ3oZL0Y6ILH1Ue8hgLTL1jIZIY_GeIWld3A5a50DJGXq1g/viewform?embedded=true"
                width="100%" height="760" frameBorder="0" marginHeight="0" marginWidth="0"/>
        </Template>
    )
}
export default Page
